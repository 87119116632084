/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  var timer;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length),
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length),
        );
      }
    }
    return fmt;
  }
}

/**
 * @param {date} seconds 需要转换的时间-s转为 时分秒
 *  @param {String} fmt 需要转换的格式 如  HH:mm:ss  HH:mm
 */
export function formatSeconds(seconds, fmt) {
  if (!seconds) return 0;
  else {
    let secondTime = parseInt(seconds); //将传入的秒的值转化为Number
    let min = 0; // 初始化分
    let h = 0; // 初始化小时
    let result = '';
    if (secondTime >= 60) {
      //如果秒数大于等于60，将秒数转换成整数
      min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
      secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
      if (min >= 60) {
        //如果分钟大于等于60，将分钟转换成小时
        h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
        min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
      }
    }
    if (h) {
      if (fmt && fmt == 'HH:mm:ss') {
        result = `${h.toString().padStart(2, '00')}:${min.toString().padStart(2, '00')}:${secondTime.toString().padStart(2, '00')}`;
      } else {
        result = `${h.toString().padStart(2, '00')}:${min.toString().padStart(2, '00')}`;
      }
    } else if (min) {
      if (fmt && fmt == 'HH:mm:ss') {
        result = `00:${min.toString().padStart(2, '00')}:${secondTime.toString().padStart(2, '00')}`;
      } else {
        result = `00:${min.toString().padStart(2, '00')}`;
      }
    } else {
      if (fmt && fmt == 'HH:mm:ss') {
        result = `00:00:${secondTime.toString().padStart(2, '00')}`;
      } else {
        result = `00:00`;
      }
    }
    return result;
  }
}
