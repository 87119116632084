import request from '@/utils/request';

const userApi = {
  outputList: '/api/output_style', // 型体产量
  machineList: '/api/machine_output', // 机器产量
  timesCountList: '/api/output_by_time', // 时间段产量

  // outputListShift: '/api/style_shift', // 型体产量 //新增班次查询方式
  // machineListShift: '/api/machine_shift', // 机器产量 //新增班次查询方式
  // timesCountListShift: '/api/output_bytime_shift', // 时间段产量 //新增班次查询方式
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

// 顶部总产量
// export function reqIndexTopCount (parameter) {
//   return request({
//     url: userApi.machineList,
//     method: 'post',
//     data: parameter
//   })
// }

// 机器产量
export function reqMachineList(parameter) {
  return request({
    url: userApi.machineList,
    method: 'post',
    data: parameter,
  });
}

// 型体产量
export function reqOutputList(parameter) {
  return request({
    url: userApi.outputList,
    method: 'post',
    data: parameter,
  });
}

// 时间段产量
export function reqOutputByTimes(parameter) {
  return request({
    url: userApi.timesCountList,
    method: 'post',
    data: parameter,
  });
}

// // 机器产量 ：指定班次
// export function reqMachineListShift(parameter) {
//   return request({
//     url: userApi.machineListShift,
//     method: 'post',
//     data: parameter,
//   });
// }

// // 型体产量 ：指定班次
// export function reqOutputListShift(parameter) {
//   return request({
//     url: userApi.outputListShift,
//     method: 'post',
//     data: parameter,
//   });
// }

// // 时间段产量 ：指定班次
// export function reqOutputByTimesShift(parameter) {
//   return request({
//     url: userApi.timesCountListShift,
//     method: 'post',
//     data: parameter,
//   });
// }
