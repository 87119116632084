<template>
  <div class="a-top-box">
    <a-form layout="inline">
      <div class="time-box">
        <div class="ymd-box">
          <div
            class="item"
            :class="item.id == dateType ? 'cur-date' : ''"
            v-for="(item, index) in searchList"
            :key="index"
            @click="handleChangeDate(item)"
          >
            {{ item.name }}
          </div>
        </div>

        <a-form-item>
          <a-config-provider :locale="locale">
            <a-range-picker
              :value="createValue"
              format="YYYY-MM-DD"
              style="width: 100%"
              @change="onSearchChange"
              :ranges="ranges"
            />
          </a-config-provider>
        </a-form-item>

        <div class="language-switch">
          <button @click="switchToEnglish">EN</button>
          <button @click="switchToChinese">中文</button>
        </div>

        <!-- 新增班次选择 -->
        <a-form-item>
          <a-select
            v-model="selectedShift"
            placeholder="请选择班次"
            style="width: 120px"
			allowClear
            @change="onShiftChange"
          >
            <a-select-option v-for="shift in shifts" :key="shift" :value="shift">
              {{ shift }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';

export default {
  data() {
    return {
      locale: zhCN,
      ranges: {
        '一月内 Last Month': () => [
          moment().startOf('day').subtract(1, 'month'),
          moment(),
        ],
        '昨天 Yesterday': () => [
          moment().startOf('day').subtract(1, 'days'),
          moment().endOf('day').subtract(1, 'days'),
        ],
        '最近一周 Last week': () => [
          moment().startOf('day').subtract(1, 'weeks'),
          moment(),
        ],
      },
      searchList: [
        { name: '日 Day', id: 0 },
        { name: '周 Week', id: 1 },
        { name: '月 Month', id: 2 },
      ],
      shifts: [0, 1, 2, 3, 4], // 班次列表
      selectedShift: undefined, // 存储选中的班次
    };
  },
  computed: {
    dateType() {
      return this.$store.state.searchType;
    },
    start_time() {
      return this.$store.state.startTimes;
    },
    end_time() {
      return this.$store.state.endTimes;
    },
    createValue() {
      return [this.$store.state.startTimes, this.$store.state.endTimes];
    },
  },
  methods: {
    handleChangeDate(row) {
      this.$store.commit('setStartTimes', '');
      this.$store.commit('setEndTimes', '');
      this.$store.commit('setSearchType', row.id);
	  
      let queryParam = {
        start_time: '',
        end_time: '',
        dateType: row.id,
        shift: this.selectedShift || 0, // 默认 0 如果未选择班次
      };
      this.$emit('onsearch', queryParam);
    },
    onSearchChange(date, dateString) {
      this.$store.commit('setStartTimes', dateString[0]);
      this.$store.commit('setEndTimes', dateString[1]);
      this.$store.commit('setSearchType', 3);

      let queryParam = {
        start_time: dateString[0],
        end_time: dateString[1],
        dateType: 3,
        shift: this.selectedShift || 0, // 默认 0 如果未选择班次
      };
      this.$emit('onsearch', queryParam);
    },
    switchToEnglish() {
      this.locale = enUS;
    },
    switchToChinese() {
      this.locale = zhCN;
    },
    onShiftChange(value) {
      this.selectedShift = value;
      this.$emit('onsearch', {
        start_time: this.start_time,
        end_time: this.end_time,
        dateType: this.dateType,
        shift: value || 0, // 传递班次
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a-top-box {
  width: 100%;
  height: 50px;

  .time-box {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 33px;
  }

  .ymd-box {
    width: 270px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 8px;

    .item {
      width: 90px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #808792;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      margin-right: 10px;
    }

    .cur-date {
      color: #ffffff;
      background: #fd870a;
    }
  }

  .language-switch {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .language-switch button {
    margin-right: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #808792;
    cursor: pointer;
  }

  .language-switch button:hover {
    border-color: #1890ff;
    border-radius: 3px;
  }
}
</style>
