import axios from 'axios';
// import storage from 'store'
import router from '../router/index';
import notification from 'ant-design-vue/es/notification';
// import message from 'ant-design-vue/es/message'
import { VueAxios } from './axios';
// import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
/**@type {import("node_modules/axios/index").AxiosInstance} */
const request = axios.create({
  /*部署包可能要应用于不同服务器，这就需要频繁更换后台地址。
      由此引申出了部署包可直接配置后台地址的需求，这样就不需要频繁打包了。 */
  baseURL: window.CUSTOM_CONFIG.BASE_URL, // 引入 config.js 的配置
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data;
    // 从 localstorage 获取 token
    const token = localStorage.getItem('Access-Token');
    if (error.response.status != 200) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      });
      localStorage.clear();
      router.push({
        path: '/error',
        query: {
          title: error.response.status,
        },
      });
    }
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      });
    }
    if (error.response.status === 402) {
      notification.error({
        message: '没有权限，请联系管理员',
        description: '授权验证失败',
      });
      if (token) {
        // store.dispatch('Logout').then(() => {
        //     setTimeout(() => {
        //         window.location.reload()
        //     }, 1500)
        // })
        localStorage.clear();
        router.push({
          path: '/login',
        });
      }
    }
    if (error.response.status === 401) {
      notification.error({
        message: '没有权限',
        description: '授权验证失败',
      });
      if (token) {
        localStorage.clear();
        router.push({
          path: '/login',
        });
      }
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = localStorage.getItem('Access-Token');
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token; // 让每个请求携带自定义 token 请根据实际情况自行修改
    let params = {
      Token: token,
    };
    config.data = Object.assign(config.data, params);
  }

  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  const data = response.data;

  if (data.Code && data.Code != 0) {
    //暂时不提示
    // notification.error({
    //     message: 'error',
    //     description: data.Msg
    // })
    localStorage.clear();
    router.push({
      path: '/login',
    });
  }
  return data;
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};

export default request;

export { installer as VueAxios, request as axios };
